<template>
  <div class="blogs">
    <div class="content-header">
      <h1>博客管理<small>发布博客</small></h1>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/' }">后台管理</el-breadcrumb-item>
        <el-breadcrumb-item>博客管理</el-breadcrumb-item>
        <el-breadcrumb-item>发布博客</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="app-container">
      <div class="blog-box" style="">
        <el-form ref="addBlogForm"  v-bind:model="blogFormData"  v-bind:rules="rules" inline="true" class="mainForm">

            <div class="ui left labeled input">
              <el-form-item prop="shareStatement" style="margin-left: 60px">
                <el-select v-model="blogFormData.shareStatement" placeholder="发布类型"
                           style="margin-right: 10px; width: 120px;margin-left: 155px">
                  <el-option
                    v-for="item in shareStatementList"
                    v-bind:key="item.id"
                    v-bind:label="item.name"
                    v-bind:value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="title" style="margin-left: 30px" label="博客标题：">
                <el-input v-model="blogFormData.title" placeholder="请输入标题" style="width: 790px"></el-input>
              </el-form-item>
            </div>

          <div class="mavonEditor" style="margin-top: 10px;">
            <el-form-item prop="content">
              <mavon-editor :codeStyle="markdownOption.codeStyle"
                            ref="md"
                            style="max-height: 1000px"
                            :scrollStyle="true"
                            :ishljs="true"
                            @imgAdd="handleEditorImgAdd"
                            @imgDel="handleEditorImgDel"
                            :toolbars="markdownOption"
                            v-model="blogFormData.content"/>
            </el-form-item>
          </div>

          <div class="two fields" style="margin-top: 3px;margin-left: 200px">
            <el-form-item prop="typeId" label="分类：">
              <el-select v-model="blogFormData.typeId" placeholder="请选择分类" style="width: 470px">
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="tagIdList" label="标签：" style="margin-left: 160px">
              <el-select v-model="blogFormData.tagIdList" multiple placeholder="请选择标签" style="width: 470px">
                <el-option
                  v-for="item in tagList"
                  :key="item.id"
                  :label="item.tagName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="field" style="margin-top: 8px;margin-left: 210px">
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-error="errorUpload"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadBlogMainImg"
              action="/file/uploadBlogMainImage">
              <el-button v-if="!blogFormData.firstPictureUrl" type="primary">上传主图</el-button>
              <img v-if="blogFormData.firstPictureUrl" :src="blogFormData.firstPictureUrl" class="avatar">
            </el-upload>
            <el-form-item v-if="false" label="博客主图路径" prop="firstPicture" style="margin-top: 8px">
              <el-input v-model="blogFormData.firstPicture" style="width: 895px"></el-input>
            </el-form-item>
            <el-form-item label="摘要：" prop="description" style="margin-top: 8px">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 5}"
                placeholder="请写一下关于文章的摘要，这将让你的博客显示在首页时，帮助你吸引更多的读者"
                v-model="blogFormData.description" style="margin-top: 10px;width: 950px">
              </el-input>
            </el-form-item>
          </div>

          <div class="inline fields" style="margin-top: 10px;margin-left: 155px">
            <el-checkbox v-model="blogFormData.archiveFlag">归档</el-checkbox>
            <el-checkbox v-model="blogFormData.appreciationFlag">赞赏</el-checkbox>
            <el-checkbox v-model="blogFormData.commentableFlag">评论</el-checkbox>
          </div>

          <div class="ui right aligned container">
            <button type="button" class="ui button" onclick="window.history.go(-1)">返回</button>
            <button type="button" id="save-btn" class="ui secondary button" v-on:click="addBlog( 1 )">保存(取消发布)</button>
            <button type="button" id="publish-btn" class="ui my-blue button" v-on:click="addBlog( 0 )">发布</button>
          </div>

        </el-form>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
// import editormd from '../../../src/assets/lib/editormd/editormd.min.js'
export default {
  data() {

    let tagIdList = [];
    let backBlogData = this.$route.query;
    backBlogData.archiveFlag = Number(backBlogData.archive) === 0;
    backBlogData.appreciationFlag = Number(backBlogData.appreciation) === 0;
    backBlogData.commentableFlag = Number(backBlogData.commentable) === 0;
    if (backBlogData.tags) {
      backBlogData.tags.forEach(tag => {
        tagIdList.push(tag.id)
      })
    }


    return {
      contentImages: [],  // 博客内容图片数组
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: '',
      rules: { // 校验规则
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur'}
        ],
        firstPicture: [
          {required: true, message: '请上传主图', trigger: 'blur'},
        ],
        content: [
          {required: true, message: '请输入正文内容', trigger: 'blur'},
          {min: 10, message: '长度最少是 10 个字符', trigger: 'blur'}
        ],
        typeId: [
          {required: true, message: '至少要有一个分类', trigger: 'blur'}
        ],
        tagIdList: [
          {required: true, message: '至少要有一个标签', trigger: 'blur'}
        ],
        shareStatement: [
          {required: true, message: '至少选择一个文章信息', trigger: 'blur'}
        ],
        description: [
          {required: true, message: '麻烦写一个简单的摘要', trigger: 'blur'},
          {min: 2, max: 100, message: '长度在 5 到 100 个字符', trigger: 'blur'}
        ]
      },
      blogFormData: {
        id: backBlogData.id ? backBlogData.id : '',
        shareStatement: backBlogData.shareStatement ? backBlogData.shareStatement : '', // 版权状态
        typeId: backBlogData.type ? backBlogData.type.id : '', // 分类id
        title: backBlogData.title ? backBlogData.title : '', // 博客标题
        content: backBlogData.content ? backBlogData.content : '', // 正文文本
        firstPicture: backBlogData.firstPicture ? backBlogData.firstPicture : '', // 博客主图OSS实例名称
        firstPictureUrl: backBlogData.firstPictureUrl,  // 博客主图url地址
        archiveFlag: backBlogData.archiveFlag , // 是否归档
        appreciationFlag: backBlogData.appreciationFlag , // 是否开启赞赏
        commentableFlag: backBlogData.commentableFlag , // 是否开启评论
        archive: backBlogData.archive , // 是否归档
        appreciation: backBlogData.appreciation , // 是否开启赞赏
        commentable: backBlogData.commentable , // 是否开启评论
        tagIdList: tagIdList, // 标签列表,
        flag: backBlogData.flag ? backBlogData.flag : '', // (默认博客编写状态为草稿)
        description: backBlogData.description ? backBlogData.description : '',
        creatorId: 819877320899141987,
        creator: {
          id: 819877320899141987
        }
      }, // 表单数据
      typeList: [],
      tagList: [],
      shareStatementList: [
        {
          id: "1",
          name: '原创'
        },
        {
          id: "2",
          name: '转载'
        },
        {
          id: "3",
          name: '翻译'
        }
      ],
      contentEditor: '',
      markdownOption: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: false, // 单双栏模式
        preview: true, // 预览
        codeStyle: 'monokai-sublime'
      }
    }
  },
  created() {
    this.getTagList()
    this.getTypeList()
    // this.getUser()
  },
  methods: {
    errorUpload() {
      this.$message.error("上传失败！")
    },
    masterFileMax(files, fileList) {
      this.$message.warning('请最多上传一张图片')
    },
    async uploadBlogMainImg(param) {
      let fileObj = param.file
      let form = new FormData()
      // 文件对象
      form.append('file', fileObj)
      const res = await this.$http.uploadFile('/file/uploadBlogMainImage', form)
      if (res.code === this.$constant.RESPONSE_CODE.SUCCESS) {
        // 弹出提示信息
        this.$message.success('上传博客主图成功')
        // 保存博客主图实例名
        const firstPictureName = res.data;
        this.blogFormData.firstPicture = firstPictureName;
        // 根据文件实例名称获取对应的URL地址
        this.blogFormData.firstPictureUrl =  await this.getBlogFirstImage(firstPictureName);
     } else { // 执行失败
        this.$message.error(res.message)
      }
    },
    handleAvatarSuccess(res, file) {
      // 上传成功回调
    //  this.blogFormData.firstPicture = URL.createObjectURL(file.raw)
    },

    async getBlogFirstImage( objectName ) {
      return ( await this.$http.get( '/file/getBlogMainImageFromOssByObjectName' , { objectName: objectName } ) ).data;
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error('上传主图图片只能是 JPG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传主图图片大小不能超过5MB!')
      }
      return isJPG && isLt5M
    },

    /**
     * 博客图片内容上传
     * @param pos 下标
     * @param $file 文件流
     */
    handleEditorImgAdd(pos, $file) {

      const blogFormData = new FormData()
      blogFormData.append('file', $file)
      this.contentImages[pos] = $file

      this.$http.uploadFile('/file/uploadBlogContentImage', blogFormData).then(res => {
        if ( res.code === this.$constant.RESPONSE_CODE.SUCCESS ) {
          this.$message.success('上传成功')
          const objectName = res.data
          this.$http.get("/file/getBlogContentImage" , { objectName: objectName } ).then( (res) => {
            // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
            this.$refs.md.$img2Url(pos, res.data);
          } ).catch( (error) => {
            this.$message.error("图片获取失败！")
          } )
        } else {
          this.$message.error(res.data.message)
        }
      }).catch( (error) => {
        this.$message.error( "上传失败" )
      } )

    },

    handleEditorImgDel(pos) {
      delete this.contentImages[pos];
      this.$message.success("删除成功！");
    },

  /*  getUser() {
      this.user = window.sessionStorage.getItem('user')
      this.nickname = JSON.parse(this.user).nickname
      this.avatar = JSON.parse(this.user).avatar
    },*/

    logout() {

    },

    addBlog( publishedCode ) {
      // 进行表单校验
      this.$refs.addBlogForm.validate( (valid) => {
        if (valid) {
          this.blogFormData.archiveFlag ? this.blogFormData.archive = 0 : this.blogFormData.archive = 1;
          this.blogFormData.commentableFlag ? this.blogFormData.commentable = 0 : this.blogFormData.commentable = 1;
          this.blogFormData.appreciationFlag ? this.blogFormData.appreciation = 0 :  this.blogFormData.appreciation = 1;
          let tags = [];
          this.blogFormData.tagIdList.forEach(tagId => {
            if( tagId ) {
              tags.push({
                id: tagId,
              })
            }
          })
          this.blogFormData.tags = tags;  // 博客对应标签集合
          if ( this.blogFormData.typeId ) {
            this.blogFormData.type = {  // 博客对应类型实体
              id: this.blogFormData.typeId
            }
          }
          let responseMessage = '发布';
          if ( this.$constant.PUBLISH_STATUS.UNPUBLISHED.CODE === publishedCode ) {
            responseMessage = '保存';
          }
          this.blogFormData.published = publishedCode;
          this.$confirm( '确定要' + responseMessage + '博客嘛？' , '提示信息' , {
            type: 'info'
          } ).then( async () => {
            // 表单校验通过，发ajax请求，把数据录入至后台处理
            const res = await this.$http.put("/blog/insertBlog", this.blogFormData)
            if (res.code === this.$constant.RESPONSE_CODE.SUCCESS) {
              // 弹出提示信息
              this.$message({
                message: '博客' + responseMessage + '成功',
                type: 'success'
              })
              // 路由跳转到博客列表页面
              this.$router.push('/blogs')
            } else { // 执行失败
              this.$message.error('博客'+ responseMessage +'失败')
            }
          } ).catch( () => {
            this.$message.info( "取消" + responseMessage )
          } )

        } else { // 校验不通过
          this.$message.error('校验失败，请检查输入格式')
          return false
        }
      })
    },
    // 获取所有的分类并回显
    async getTypeList() {
      const res = await this.$http.get('/type/listTypes')
      this.typeList = res.data.list
    },
    // 获取所有的标签并回显
    async getTagList() {
      const res = await this.$http.get('/tag/listTags')
      this.tagList = res.data.list
    }
  },
  mounted() {
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })

    $('.ui.dropdown').dropdown({
      on: 'hover'
    })
    $('.ui.form').form({
      // fields: {
      //   title: {
      //     identifier: 'title',
      //     rules: [{
      //       type: 'empty',
      //       prompt: '标题：请输入博客标题'
      //     }]
      //   }
      // }
    })
  }
}
</script>

<style scoped>
@import "../../../assets/css/me.css";

.mavonEditor {
  width: 75%;
  height: 100%;
  margin: 0 auto;
}

.blog-box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #3c8dbc;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.avatar-uploader .el-upload {
  border: 5px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 60px;
  height: 60px;
  display: block;
}

</style>
